import React from "react";
import MoreInformation from "../components/Trips/MoreInformation";
import { Trans } from "react-i18next";
import { presentationTrips } from "../components/Trips/trips-data";

const tripInformation = presentationTrips.find(
  (x) => x.id === "sant-vicenc-trip"
);

const Cala = () => {
  return (
    <MoreInformation
      title="Cala Sant Vicenç - El Colomer"
      description="Embark on an unforgettable adventure during our Cala Sant Vicenç and El Colomer excursion! Cruise along the stunning Sierra Norte coastline, admiring the picturesque coves, rugged landscapes, and dramatic cliffs that dot the shoreline."
      tripInformation={tripInformation}
      timeTable={
        <>
          <Trans i18nKey={tripInformation.moreInformation.timetable}>
            <ul>
              <li>Item 1</li>
              <li>Item 2</li>
            </ul>
          </Trans>
        </>
      }
    />
  );
};

export default Cala;
